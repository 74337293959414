import React from 'react';

const ConfirmationDialog = ({ show, title, message, onConfirm, onCancel }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="jmodal-overlay">
      <div className="jmodal-content">
        <h2>{title}</h2>
        <p>{message}</p>
        <div className="jmodal-buttons">
          <button onClick={onConfirm} className="confirm-button">Confirm</button>
          &nbsp;
          <button onClick={onCancel} className="cancel-button">Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;