import React from'react';
import { formatCurrency } from '../../tools/tool';
import { ReactComponent as TransactionIcon} from '../../icons/item.svg';
const ItemRequestsCard = () => {
  return (
        <div className='card bg-success text-white mb-3' style={{width: '18rem',marginRight: '5px'}}>
            <div className='card-header d-flex flex-row justify-content-center align-items-center'>
                <TransactionIcon className='mb-1' style={{width: '2rem', height: '2rem'}} />&nbsp;
                <span className='h5'>Item Requests</span>
            </div>
            <div className='card-body' style={{minHeight: '136px'}}>
                <h5 className='card-title text-center h1'>{0}</h5>
                <p className='card-text text-center'>Total number of item requests</p>
            </div>
            <div className='card-footer bg-transparent' style={{textAlign:'right'}}>
                <a href='#' className='btn btn-outline-light btn-sm'>View Details</a>
            </div>
        </div>  
  );

  };

export default ItemRequestsCard;