import React,{ useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {ReactComponent as VoidIcon} from '../icons/void.svg';
import LoadingModal from "../components/LoadingModal";
import { adminServices } from "../services/AdminServices";
import SearchBar from "../components/SearchBar";
import Button from "../components/Button";

const VoidTransactions = () => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        setLoading(true);
        getVoidTransactions();
    },[]);

    const exportToCSV = (data) => { const csvRows = [ Object.keys(data[0]).join(',') ].concat( data.map(row => Object.values(row).join(',')) ).join('\n'); const blob = new Blob([csvRows], { type: 'text/csv;charset=utf-8;' }); const url = URL.createObjectURL(blob); const link = document.createElement('a'); link.setAttribute('href', url); link.setAttribute('download', 'VoidTransactions.csv'); link.click(); };

    const getVoidTransactions = async ()=>{
        var form  = new FormData();
        form.append("params", "");
        await adminServices.storedProcedure('void_transactions',form).then((r)=>{
            setLoading(false);
            setData(r.data);
            let _col = [
                { name: 'Voucher/Reference No', selector: data=>data.ref_no, sortable: true },
                { name: 'PDL No', selector: data=>data.pdl_number, sortable: true },
                { name: 'PDL Name', selector: data=>data.pdl_name, sortable: true },
                { name: 'Transaction', selector: data=>data.transaction_type, sortable: true },
                { name: 'Transaction Date', selector: data=>data.transaction_date, sortable: true },
                { name: 'Voided Date', selector: data=>data.voided_date, sortable: true },
                { name: 'Amount', selector: data=>data.amount.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }), sortable: true },
                { name: 'Void Reason', selector: data=>data.reason, sortable: true }
            ]
            setColumns(_col);
        }).catch((e)=>{
            console.log(e);
        });
    }

    return (
        <div>
            <LoadingModal show={loading} message={"Loading Void Transactions..."} />
            <div className="sc-fPXMhL QAQQD" style={{fontSize:'1.5rem', fontWeight:'bold', marginBottom:10, display: 'flex', justifyContent:'start',alignItems:'center'}}>
                <VoidIcon style={{ width: "50px", height: "50px", marginRight: "10px" }} />
                <h3>Void Transactions</h3>
            </div>

            <div className="flexRow">
                <div className="flexRow filterDiv">
                {/* <SearchBar
                    placeholder="Search Void Transactions"
                    onChange={(e)=>{
                    const value = e.target.value.toLowerCase();
                    const filtered = data.filter(item => 
                        Object.keys(item).some(key => 
                        item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase()) 
                        ) 
                    );
                    setFilteredData(filtered);
                    }}
                /> */}
                </div>
                &nbsp;&nbsp;
                <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }}
                >
                </div>
                <div className="spacer"></div>
                <Button variant={"success"} size={'xs'} onClick={()=>exportToCSV(data)}> Export to CSV </Button>
            </div>

            <DataTable
                columns={columns}                
                data={data}
                pagination
                highlightOnHover
            />
        </div>);

};

export default VoidTransactions;