import React from "react";

const InputNum = ({ title, placeholder, name, value, onChange, readonly=false,type='text', onClick}) => {
  return (
    <div style={{ margin: "5px", flex: "1" }}>
      <p className="semibold ">{title}</p>
      <input
        className="r form1"
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        readOnly={readonly ? "readonly" : ""}
        onClick={onClick}
        
      ></input>
    </div>
  );
};

export default InputNum;
