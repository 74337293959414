import React, { useEffect, useState } from "react";
import Modal from "react-modal"
import { adminServices } from "../../services/AdminServices";
import InputText1 from '../InputText1';
import InputText2 from '../InputText2';
import Button from '../Button';
import LoadingModal from "../LoadingModal";
const customStyles = { 
    overlay: { 
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    }, 
    content: {
        width: '50%', margin: 'auto', 
        height : '450px'
      
    },
 };

const MerchantFormComponent = ({show,onClose,data = null, onAfterSubmit, onError})=>{
    const [merchantName, setMerchantName] = useState('');
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');
    const [status, setStatus] = useState(1);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        console.log(data);
        if(data){
            setMerchantName(data.merchant_name);
            setDescription(data.description);
            setLocation(data.location);
        }
        else{
            setMerchantName("");
            setDescription("");
            setLocation("");
            setValidated(false);
        }
    },[data]);

    const validateForm = () => {
        if(merchantName == "" || description == "" || location == ""){
            return false;
        }

        return true;
    }

    const handleSubmit = async () => {
        if(validateForm()){
            setLoading(true);
            
            let formData = new FormData();
            formData.append('params',`${data.id},'${merchantName}','${description}','${location}'`)

            adminServices.storedProcedure('UpdateMerchantDetails',formData).then((response)=>{
                if(response.status == 200 || response.status == 204){
                    setLoading(false);
                    onClose();
                    onAfterSubmit();
                }
            }).catch((error)=>{
                setLoading(false);
                onError();
                onClose();
            });
            
        }
        else{
            setValidated(true);
        }
    }

    return (
        <Modal
            
            isOpen={show}
            shouldCloseOnEsc={false} 
            shouldCloseOnOverlayClick={false}
            style={customStyles}
            onRequestClose={onClose}
        >
            <LoadingModal show={loading} message={"Saving data..."} />
         <div style={{display:"flex",justifyContent:'space-between',zIndex:999}}>
                <h3>Merchant Management</h3>
                <button style={{backgroundColor:'#fff',
                    borderColor:'#d2d2d2',
                    color:'#555',
                    borderRadius:100,
                    fontSize:20,
                    fontWeight: 'bolder',
                    fontFamily: 'monospace',
                    borderWidth:0}}
                    onClick={onClose}
                    >
                        x
                    </button>
            </div>

            <div className="flexRow">
                <InputText1 title={"Merchant Name"} value={merchantName} onChange={(e)=>{ setMerchantName(e.target.value) }} />
            </div>

            <div className="flexRow">
                <InputText1 title={"Description"} value={description} onChange={(e)=>{ setDescription(e.target.value) }} />
            </div>

            <div className="flexRow">
                <InputText1 title={"Location"} value={location} onChange={(e)=>{ setLocation(e.target.value) }} />
            </div>

            <div className="flexRow" style={{justifySelf:'end'}}>
                    <div>
                        {validated ? <p style={{color:'red',marginTop:10}}>Please fill all fields&nbsp;</p> : ""}
                    </div>
                    <Button variant={"success"} size={'md'} onClick={handleSubmit}>
                        Submit
                    </Button>
                    &nbsp;
                    <Button variant={"secondary"} size={'md'} onClick={onClose}>
                        Cancel
                    </Button>
            </div>
        </Modal>
    )
}

export default MerchantFormComponent;