import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ variant, size, onClick, children }) => {
  const className = `btn btn-${variant} btn-${size}`;

  return (
    <button className={className} onClick={onClick}>
      {children}
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  variant: 'primary',
  size: 'md',
  onClick: () => {},
};

export default Button;
