import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import { adminServices } from "../services/AdminServices";

const LogInPage = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState('');
  const [errorLogin,setErrorLogin] = useState('');
  const [loading, setLoading] = useState('');
  

  const handleUsername = (event)=>{
    setUserName(event.target.value);
    setErrorLogin('');
  }

  const handlePassword = (event)=>{
    setPassword(event.target.value);
    setErrorLogin('');
  }

  const Login = ()=>{
    setLoading('Logging-In, please wait!');
    adminServices.login(userName,password).then((r)=>{
      if(r.status == 200){
          localStorage.setItem("secretKey",r.data.secretKey);
          localStorage.setItem('emailAddr',userName);
          window.location.href = "/verify-otp";
      }
      else{
        setLoading('Unauthorized');
      }
    }).catch((e)=>{
      setErrorLogin('Invalid credentials');
      setLoading('');
    });

  }

  function togglePassword() {
    var passwordInput = document.getElementById("password");
    var showPasswordCheckbox = document.getElementById("showPassword");

    if (showPasswordCheckbox.checked) {
      passwordInput.type = "text";
    } else {
      passwordInput.type = "password";
    }
  }

  return (
    <div className="mainContainer">
      {/* Right Side */}
      <div className="loginPageContainer">
        <div className="loginPartition1">
          <div className="leftPart">
            {/* <div>
              <p>Login</p>
            </div>
            <div>
              <p>Sign Up</p>
            </div> */}
          </div>
        </div>

        {/* Left Side */}
        <div className="loginPartition2">
          <div className="rightPart">
            <div>
              <p className="xl medium">Welcome!</p>
              <p className="r light">Let’s pick up where we left off.</p>
              <div style={{ height: "25px" }}></div>
              <div className="loginFields regular">
                <p>Email Address</p>
                <input
                  className="m"
                  type="text"
                  placeholder="Username"
                  value={userName}
                  onChange={handleUsername}
                ></input>
              </div>
              <div className="loginFields regular">
                <p>Password</p>
                <input
                  id="password"
                  className="m"
                  type="password"
                  placeholder="********"
                  value={password}
                  onChange={handlePassword}
                ></input>
              </div>

              <div className="flexRow" style={{color:'#FF0000',marginBottom:15, justifyContent:'center'}}>
                {errorLogin}
              </div>

              <div className="flexRow" style={{color:'green',marginBottom:15, justifyContent:'center'}}>
                {loading}
              </div>

              <div className="flexRow">
                <div className="flexRow m">
                  <input
                    type="checkbox"
                    id="showPassword"
                    onChange={togglePassword}
                  ></input>
                  <p>&nbsp;Show password</p>
                </div>
                <div className="spacer"></div>
                <Link
                  style={{ textDecoration: "none" }}
                  className="t-blue m medium"
                  to=""
                >
                  Forgot password?
                </Link>
              </div>
              <div style={{ height: "20px" }}></div>
              <div className="loginButton">
                <Link style={{ textDecoration: "none" }}  onClick={Login}>
                  <p className="r">Sign in</p>
                </Link>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "20px 0",
                }}
              >
                {/* <div className="flexRow m">
                  <p>Didn't have an account?</p> &nbsp;&nbsp;
                  <Link
                    className="t-blue medium"
                    style={{ textDecoration: "none" }}
                    to=""
                  >
                    Sign up
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogInPage;
