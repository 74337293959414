import React, { useEffect, useState } from'react';
import { formatCurrency } from '../../tools/tool';
import { ReactComponent as TransactionIcon} from '../../icons/report.svg';
import { adminServices } from '../../services/AdminServices';
import { useNavigate } from 'react-router-dom';
const ForReprocessCard = () => {
  const navigate = useNavigate();
  const [totalTransaction, setTotalTransaction] = useState(0);
  
  useEffect(() => {
    let formData = new FormData();
    formData.append('params','');
    adminServices.storedProcedure('padala_for_processing', formData).then((response) => {
        if(response.status === 200){
            setTotalTransaction(response.data.length);
        }
    })
  },[]);



  return (
        <div className='card bg-danger text-white mb-3' style={{width: '18rem',marginRight: '5px'}}>
            <div className='card-header d-flex flex-row justify-content-center align-items-center'>
                <TransactionIcon className='mb-1' style={{width: '2rem', height: '2rem'}} />&nbsp;
                <span className='h5'>For Reprocessing</span>
            </div>
            <div className='card-body' style={{minHeight: '136px'}}>
                <h5 className='card-title text-center h1'>{totalTransaction}</h5>
                <p className='card-text text-center'>Total number of padala for reprocessing</p>
            </div>
            <div className='card-footer bg-transparent' style={{textAlign:'right'}}>
                <a href='javascript:void(0)' onClick={()=>{
                    navigate('/floating-padala-reports');
                }} className='btn btn-outline-light btn-sm'>View Details</a>
            </div>
        </div>  
  );

  };

export default ForReprocessCard;