import React, { useEffect, useState } from "react";
import { padalaServices } from "../services/PadalaServices";
import { adminServices } from "../services/AdminServices";
import DataTable from "react-data-table-component";
import {ReactComponent as TransactionIcon} from '../icons/transactions.svg';
import { useNavigate } from "react-router-dom";
import LoadingModal from "../components/LoadingModal";
import SearchBar from "../components/SearchBar";
import Button from "../components/Button";
const MerchantPadalaReports = () => {
  const navigate = useNavigate();
  const [padalaList, setPadalaList] = useState(null);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    setLoading(true);
    getPadala();
  },[]);

  const exportToCSV = (data) => { const csvRows = [ Object.keys(data[0]).join(',') ].concat( data.map(row => Object.values(row).join(',')) ).join('\n'); const blob = new Blob([csvRows], { type: 'text/csv;charset=utf-8;' }); const url = URL.createObjectURL(blob); const link = document.createElement('a'); link.setAttribute('href', url); link.setAttribute('download', 'MerchantPadalaReport.csv'); link.click(); };

  const getPadala = async ()=>{
    var form = new FormData();
    form.append('params','');
    await adminServices.storedProcedure('merchant_padala_report',form).then((r)=>{
      setPadalaList(null);
      setPadalaList(r.data);
      setData(r.data);
      setFilteredData(r.data);

      var _col = [
        { name: 'Merchant Name', selector: data=>data.merchant_name, sortable: true },
        { name: 'PDL Number', selector: data=>data.pdl_number, sortable: true },
        { name: 'PDL Name', selector: data=>data.pdl_name, sortable: true },
        { name: 'Gross Amount', selector: data=>data.gross_amount.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }), sortable: true, right: true,
        headerStyle: {
          textAlign: 'center',
        }, },
        { name: 'Interest Rate', selector: data=>data.interest_rate.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }), sortable: true, right: true,
        headerStyle: {
          textAlign: 'center',
        }, },
        { name: 'Net Amount', selector: data=>data.net_amount.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }), sortable: true, right: true,
          headerStyle: {
            textAlign: 'center',
          }, },
        { name: 'Reference' , selector: data=>data.ref_no, sortable: true },
        { name: 'Transaction Date', selector: data=>data.reg_date, sortable: true },
        { name: 'Sender', selector: data=>data.sender, sortable: true },
      ];

      setColumns(_col);
      setLoading(false);

    }).catch((e)=>{
      if(e.status == 401){
        window.location.href = '/';
      }
    });
    

  }

  return (
    <div>
      <LoadingModal show={loading} message={"Loading Padala Report"} />
      <div style={{fontSize:'1.5rem', fontWeight:'bold', marginBottom:10, display: 'flex', justifyContent:'start',alignItems:'center'}}>
          <TransactionIcon style={{ width: "50px", height: "50px", marginRight: "10px",background:'transparent' }} />
          Merchant Padala Report
      </div>

      <div className="flexRow">
        <div className="flexRow filterDiv">
          <SearchBar
            placeholder="Search Padala Report"
            onChange={(e)=>{
              const value = e.target.value.toLowerCase();
              const filtered = data.filter(item => 
                Object.keys(item).some(key => 
                  item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase()) 
                ) 
              );
              setFilteredData(filtered);
            }}
          />
        </div>
        &nbsp;&nbsp;
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
        </div>
        <div className="spacer"></div>
        <Button variant={"success"} size={'xs'} onClick={()=>exportToCSV(filteredData)}> Export to CSV </Button>
      </div>
      <br />
      <DataTable 
          columns={columns}
          data={filteredData}
          pagination
          highlightOnHover
          style={{zIndex:0}}
      />
    </div>
  );
};

export default MerchantPadalaReports;
