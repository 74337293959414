import React, { useEffect, useState } from'react';
import { formatCurrency } from '../../tools/tool';
import {ReactComponent as WalletIcon} from '../../icons/wallet.svg';
import { adminServices } from "../../services/AdminServices"
import { useNavigate } from 'react-router-dom';
const IPXWalletCard = () => {
    const navigate = useNavigate();
    const [walletValue, setWalletValue] = useState(0);
    useEffect(() => {
        setWalletValue(0);
        getIpxWallet();
    },[]);

    const getIpxWallet = async () => {
        let formData = new FormData();

        formData.append('params', '');
        await adminServices.storedProcedure('ipx_wallet',formData).then((response)=>{
            if(response.status == 200){
                setWalletValue(response.data[0].interest_fee);
            }
        }).catch((error) => {
            if(error.status == 401){
                navigate('/');
            }
        })
    }


  return (
        <div className='card bg-teal mb-3' style={{width: '25rem',marginRight: '5px'}}>
            <div className='card-header d-flex flex-row justify-content-center align-items-center'>
                <WalletIcon className='mb-1' style={{width: '2rem', height: '2rem'}} />&nbsp;
                <span className='h5'>IPX Wallet</span>
            </div>
            <div className='card-body' style={{minHeight: '136px'}}>
                <h5 className='card-title text-center h1'>{formatCurrency(walletValue)}</h5>
                <p className='card-text text-center'>Overall Income</p>
            </div>
            {/* <div className='card-footer bg-transparent' style={{textAlign:'right'}}>
                <a href='' className='btn btn-outline-light btn-sm'>View Details</a>
            </div> */}
        </div>  
  );

  };

export default IPXWalletCard;