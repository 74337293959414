import React from 'react';

const AlertModal = ({ show, title, message, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="jmodal-overlay">
      <div className="jmodal-content">
        <h2>{title}</h2>
        <p>{message}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default AlertModal;