import React from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { useState, useEffect } from "react";
import SearchBar from "../components/SearchBar";
import Button1 from "../components/Button1";
import { ReactComponent as CloseIcon } from "../icons/close.svg";
import { ReactComponent as EditIcon } from "../icons/edit.svg";
import DataTable from "react-data-table-component";
import { adminServices } from "../services/AdminServices";
import ConfirmationDialog from "../components/ConfirmationDialog";
import AlertModal from "../components/AlertModal";
import LoadingModal from "../components/LoadingModal";
import { ReactComponent as TransactionIcon } from "../icons/merchant.svg";
import MerchantFormComponent from "../components/merchants/MerchantForm";

const MerchantPage = () => {
  const [kioskSales, setKioskSales] = useState([]);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [showAlert,setShowAlert] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showFormModal,setShowFormModal] = useState(false);
  const [selectedData,setSelectedData] = useState(null);
  const [showError, setShowError] = useState(false);
  const [message,setMessage]  = useState("");

  //modal function
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const openViewMerchantModal = (merchant) => {
    setSelectedMerchant(merchant);
    setModalIsOpen(true);
  };

  const closeViewMerchantModal = () => {
    setModalIsOpen(false);
    setSelectedMerchant(null);
  };

  const handleButtonClick = (r)=>{
    setShowAlert(true);
    setSelectedMerchant(r);
  }

  //search function
  const [searchTerm, setSearchTerm] = useState("");
  const [displayedProducts, setDisplayedProducts] = useState([]);

  useEffect(() => {
    setLoading(true);
    getPadala();

  }, []);

  // setTimeout(()=>{
  //   getPadala();
  // },60000);

 const handleEditClick = (r)=>{
  setShowFormModal(true);
  setSelectedData(r);
 }

 const customStyles = { headCells: { style: { fontWeight: 'bold', }, }, cells: { style: { wordBreak: 'break-word', whiteSpace: 'normal', }, }, };

  const getPadala = ()=>{
    var form = new FormData();
    form.append('params','');
    adminServices.storedProcedure('pdl_merchants',form).then((r)=>{
      setKioskSales(null);
      setKioskSales(r.data);
      setData(r.data);
      setFilteredData(r.data);

      var _col = [
        { name: 'PDL Name', selector: data=>data.first_name + " " + data.last_name, sortable: true },
        { name: 'Merchant Name', selector: data=>data.merchant_name, sortable: true },
        { name: 'Description', selector: data=>data.description , sortable: true },
        { name: 'Type', selector: data=>data.type, sortable: true },
        { name: 'Location', selector: data=>data.location, sortable: true, right: false},
        { name: 'Date Registered', selector: data=>data.reg_date, sortable: true },
        { name: 'Wallet', selector: data=>"Php "+ data.wallet_amount.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }), sortable: true, right: true },
        { name: 'Action',right:true ,selector: row => row.type === 'Store' ? ( <div> <button type="button" onClick={() => handleButtonClick(row)}>View</button> <button type="button" onClick={() => handleEditClick(row)} style={{ marginLeft: '10px' }}>Edit</button> </div> ) : (<div><button type="button" onClick={() => handleEditClick(row)} style={{ marginLeft: '10px' }}>Edit</button></div> )}
      ]

    setColumns(_col);
    setLoading(false);
    }).catch((e)=>{
      if(e.response.status == 401){
        window.location.href="/";
      }
    })

  }

  const handleRowClick = (row) => {
    var mid = btoa(JSON.stringify(row));
    localStorage.setItem("selectedMerchant",mid);
    navigate("/merchant-transactions");
  }

  

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [merchantsPerPage, setMerchantsPerPage] = useState(10);

  // Get current merchants
  const indexOfLastProduct = currentPage * merchantsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - merchantsPerPage;
  const currentProducts = displayedProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="mainContainer">
      <MerchantFormComponent show={showFormModal} data={selectedData} 
      onClose={()=>{ setShowFormModal(false); setSelectedData(null); }}  
      onAfterSubmit={()=>{
        setSelectedData(null);
        setMessage("Merchant saved successfully");
        setShowError(true);
        getPadala();
      }}
      onError={()=>{
        setMessage("Error saving supplier");
        setShowError(true);
      }}
        />
      <LoadingModal show={loading} message={"Loading Merchants..."} />
      <AlertModal
       show={showAlert}
       title={selectedMerchant &&  selectedMerchant.merchant_name + " - " + selectedMerchant.username}
       message={"Merchant Password is: " + (selectedMerchant && selectedMerchant.password) + ""}
       onClose={()=>{
        setShowAlert(false);
        setSelectedMerchant(null);
       }}
      />
      <AlertModal show={showError} title={"Merchant Management"} message={message} onClose={()=>{ setShowError(false);  }} />

      {/* View merchant details modal */}
      
      {/* Header */}
      <div style={{fontSize:'1.5rem', fontWeight:'bold', marginBottom:10, display: 'flex', justifyContent:'start',alignItems:'center'}}>
          <TransactionIcon style={{ width: "50px", height: "50px", marginRight: "10px",background:'transparent' }} />
          Merchants
      </div>

      <div style={{ height: "25px" }}></div>

      {/* Search Row */}
      <div className="flexRow">
        <div className="flexRow filterDiv">
          <SearchBar
            placeholder="Search Merchants"
            onChange={(e)=>{
              const value = e.target.value.toLowerCase();
              const filtered = data.filter(item => 
                Object.keys(item).some(key => 
                  item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase()) 
                ) 
              );
              setFilteredData(filtered);
            }}
          />
        </div>
        &nbsp;&nbsp;
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
        </div>
        <div className="spacer"></div>
        <Button1
          text={"Add merchant"}
          onClick={() => {
            navigate("/merchant/add");
          }}
        />
      </div>

      

      <div style={{ height: "25px" }}></div>


      {/* Table */}
      <div className="merchantTableContainer">
        <DataTable
            customStyles={customStyles}
            responsive
            overflowX="scroll"
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
            style={{zIndex:0}}
            onRowClicked={handleRowClick}
        />
      </div>

      {/* <div style={{ height: "10px" }}></div> */}

      {/* Table Footer */}
      
          
      {/*  */}
    </div>
  );
};

export default MerchantPage;
