import React, { useState, useEffect } from "react";
import SearchBar from "../SearchBar";
import DataTable from "react-data-table-component";
import {ReactComponent as SupplierIcon} from "../../icons/supplier.svg";
import { adminServices } from "../../services/AdminServices";
import { useAsyncError, useNavigate } from "react-router-dom";
import LoadingModal from "../LoadingModal";
import SupplierFormComponent from "./SupplierForm";
import AlertModal from "../AlertModal";
import Button from "../Button";

const SupplierComponent = () => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showFormModal, setShowFormModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        initializeColumns();
        getSuppliers();

        return () => {
            console.log("Page initialized");
        }
    },[]);

    const initializeColumns = () => {
        const _columns = [
            { name: 'Supplier Name', selector: row => row.supplier_name, sortable: true, },
            { name: 'Contact Person', selector: row => row.contact_name, sortable: true, },
            { name: 'Email Address', selector: row => row.email, sortable: true, },
            { name: 'Phone Number', selector: row => row.contact_no, sortable: true, },
            { name: 'Fax No', selector: row => row.fax_no, sortable: true, },
            { name: 'Address', selector: row => `${row.address} ${row.city} ${row.region}`, sortable: true, },
            {name : 'Status', selector: row=>`${row.status === 1 ? 'Active' : 'Inactive' }`, sortable: true},
        ];

        setColumns(_columns);
    }

    const onEditClick = (e)=>{
        setSelectedData(e);
        setShowFormModal(true);
    }

    const getSuppliers = async() => {
        let form = new FormData();
        form.append('params','');
        await adminServices.storedProcedure('get_supplier_list',form).then((res)=>{
            if(res.status === 200){
                setData(res.data);
                setLoading(false);
            }
        }).catch((error)=>{

            if(error.status === 401){
                navigate('/');
            }

        })
    }

    return (<div>
        <SupplierFormComponent show={showFormModal} data={selectedData}  
        onClose={()=>{ setShowFormModal(false); setSelectedData(null); }}  
        onAfterSubmit={()=>{ 
            setSelectedData(null);
            setAlertMessage("Supplier saved successfully");
            setShowAlert(true);
        }}

        onError={()=>{
            setAlertMessage("Error saving supplier");
            setShowAlert(true);
        }}
        />
        <LoadingModal show={loading} message={"Loading suppliers..."} />
        <AlertModal show={showAlert} message={alertMessage} onClose={ async () => { setShowAlert(false); setAlertMessage(""); await getSuppliers(); }}    />
        {/* <div className="flexRow" style={{backgroundColor: '#deafaf'}}>
            <SupplierIcon className="inv-icon crumb"/>
            <h2>Suppliers&nbsp;</h2>
            <div style={{backgroundColor:'red'}}>
                <Button variant={"secondary"} size={'xs'} onClick={() => { setShowFormModal(true); setSelectedData(null); }}> + </Button>
            </div>
        </div> */}
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <SupplierIcon className="inv-icon crumb"/>
                <h2>Suppliers&nbsp;</h2>
            </div>
            <div style={{marginRight: '10px'}}>
                <Button variant={"primary"} size={'xs'} onClick={() => { setShowFormModal(true); setSelectedData(null); }}> + Add Supplier </Button>
            </div>
        </div>

        <div className="flexRow filterDiv">
            <SearchBar
            placeholder="Search Suppliers"
            onChange={()=>{ }}
            />
        </div>

        <div className="spacer-v"></div>
        <div className="tableContainer">
            <DataTable pagination={true}
                columns={columns}
                data={data}
                onRowClicked={onEditClick} 
                pointerOnHover/>
        </div>
    </div>)
}

export default SupplierComponent;