import React, { useEffect, useState } from "react";
import Modal from "react-modal"
import { adminServices } from "../../services/AdminServices";
import InputText1 from '../InputText1';
import InputText2 from '../InputText2';
import Button from '../Button';
import LoadingModal from "../LoadingModal";
const customStyles = { 
    overlay: { 
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    }, 
    content: {
        width: '80%', margin: 'auto', 
        height : '500px'
      
    },
 };

const SupplierFormComponent = ({show,onClose,data = null, onAfterSubmit, onError})=>{
    const [supplierName, setSupplierName] = useState("");
    const [contactName, setContactName] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [region, setRegion] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [faxNo, setFaxNo] = useState("");
    const [status, setStatus] = useState(1);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        if(data){
            setSupplierName(data.supplier_name);
            setContactName(data.contact_name);
            setEmail(data.email);
            setAddress(data.address);
            setCity(data.city);
            setRegion(data.region);
            setFaxNo(data.fax_no);
            setContactNo(data.contact_no);
        }
        else{
            setSupplierName("");
            setContactName("");
            setEmail("");
            setAddress("");
            setCity("");
            setRegion("");
            setFaxNo("");
            setContactNo("");
            setValidated(false);
        }
    },[data]);

    const validateForm = () => {
        if(supplierName == "" || contactName == "" || email == "" || address == "" || city == "" || region == "" || contactNo == "" || faxNo == ""){
            return false;
        }

        return true;
    }

    const handleSubmit = async () => {
        if(validateForm()){
            setLoading(true);
            let payload = {
                supplierName: supplierName,
                contactName: contactName,
                email: email,
                address: address,
                city: city,
                region: region,
                contactNo: contactNo,
                faxNo: faxNo,
                status: 1
            }

            if(data == null){
                adminServices.postData('suppliers',payload).then((response)=>{
                    setLoading(false);
                    if(response.status == 201){
                        onClose();
                        onAfterSubmit();
                    }
                }).catch((error)=>{
                    setLoading(false);
                    onError();
                    onClose();
                });
            }
            else{
                payload.id = data.id;
                adminServices.updateData('suppliers/' + data.id,payload).then((response)=>{
                    if(response.status == 201 || response.status == 204){
                        setLoading(false);
                        onClose();
                        onAfterSubmit();
                    }
                }).catch((error)=>{
                    setLoading(false);
                    onError();
                    onClose();
                });
            }
            
        }
        else{
            setValidated(true);
        }
    }

    return (
        <Modal
            
            isOpen={show}
            shouldCloseOnEsc={false} 
            shouldCloseOnOverlayClick={false}
            style={customStyles}
            onRequestClose={onClose}
        >
            <LoadingModal show={loading} message={"Saving data..."} />
         <div style={{display:"flex",justifyContent:'space-between',zIndex:999}}>
                <h3>Supplier Management</h3>
                <button style={{backgroundColor:'#fff',
                    borderColor:'#d2d2d2',
                    color:'#555',
                    borderRadius:100,
                    fontSize:20,
                    fontWeight: 'bolder',
                    fontFamily: 'monospace',
                    borderWidth:0}}
                    onClick={onClose}
                    >
                        x
                    </button>
            </div>

            <div className="flexRow">
                <InputText1 title={"Supplier Name"} value={supplierName} onChange={(e)=>{ setSupplierName(e.target.value) }} />
                <InputText1 title={"Contact Name"} value={contactName} onChange={(e)=>{ setContactName(e.target.value) }} />
                <InputText1 title={"Email Address"} value={email} onChange={(e)=>{ setEmail(e.target.value) }} />
            </div>

            <div className="flexRow">
                <InputText1 title={"Address"} value={address} onChange={(e)=>{ setAddress(e.target.value) }} />
                <InputText1 title={"City"} value={city} onChange={(e)=>{ setCity(e.target.value) }} />
                <InputText1 title={"Region"} value={region} onChange={(e)=>{ setRegion(e.target.value) }} />
            </div>

            <div className="flexRow">
                <InputText1 title={"Contact #"} value={faxNo} onChange={(e)=>{ setFaxNo(e.target.value) }} />
                <InputText1 title={"Fax No"} value={contactNo} onChange={(e)=>{ setContactNo(e.target.value) }}/>
                <div style={{width: '33%'}}>
                    {data == null ? "" : data.status == 1 ? "Active" : 'Inactive'}
                </div>
            </div>

            <div className="flexRow" style={{justifySelf:'end'}}>
                    <div>
                        {validated ? <p style={{color:'red',marginTop:10}}>Please fill all fields&nbsp;</p> : ""}
                    </div>
                    <Button variant={"success"} size={'md'} onClick={handleSubmit}>
                        Submit
                    </Button>
                    &nbsp;
                    <Button variant={"secondary"} size={'md'} onClick={onClose}>
                        Cancel
                    </Button>
            </div>
        </Modal>
    )
}

export default SupplierFormComponent;