import React, { useEffect, useState } from "react";
import { padalaServices } from "../services/PadalaServices";
import { adminServices } from "../services/AdminServices";
import DataTable, { Alignment } from "react-data-table-component";
import { ReactComponent as TransactionIcon } from "../icons/receipt.svg";
import LoadingModal from "../components/LoadingModal";
import Button from '../components/Button';
import SearchBar from "../components/SearchBar";

const KioskReports = () => {
  const [kioskSales, setKioskSales] = useState(null);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    setLoading(true);
    getPadala();

  },[]);

  const exportToCSV = (data) => { const csvRows = [ Object.keys(data[0]).join(',') ].concat( data.map(row => Object.values(row).join(',')) ).join('\n'); const blob = new Blob([csvRows], { type: 'text/csv;charset=utf-8;' }); const url = URL.createObjectURL(blob); const link = document.createElement('a'); link.setAttribute('href', url); link.setAttribute('download', 'KioskSalesReport.csv'); link.click(); };

  const getPadala = ()=>{
    var form = new FormData();
    form.append('params','');
    adminServices.storedProcedure('kiosk_sales_report',form).then((r)=>{
      setKioskSales(null);
      setKioskSales(r.data);
      setData(r.data);
      setFilteredData(r.data);

      var _col = [
        { name: 'ID', selector: row=>row.sequence, sortable: true },
        { name: 'Voucher Number', selector: row=>row.voucher_number, sortable: true },
        { name: 'PDL Name', selector: row=>row.pdl_name, sortable: true },
        { name: 'Service', selector: row=>row.service, sortable: true },
        { name: 'Gross Amount', selector: row=>row.total_price.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }), sortable: true, right: true,
          headerStyle: {
            textAlign: 'center',
          }, },
        { name: 'Net Amount', selector: row=>row.price.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }), sortable: true, right: true,
          headerStyle: {
            textAlign: 'center',
          }, },
          { name: 'Service Fee', selector: row=>row.service_fee.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }), sortable: true, right: true,
            headerStyle: {
              textAlign: 'center',
            }, },
        { name: 'Transaction Date', selector: row=>row.reg_date, sortable: true },
        { name: 'Merchant Name', selector: row=>row.merchant_name, sortable: true }
    ]
    setColumns(_col);
    setLoading(false);

    }).catch ((error)=>{
      if(error.status === 401){
        window.location.href = "/";
      }
    })

  }

  return (
    <div>
      <LoadingModal show={loading} message={"Loading Kiosk Sales Report..."}/>
      <div style={{fontSize:'1.5rem', fontWeight:'bold', marginBottom:10, display: 'flex', justifyContent:'start',alignItems:'center'}}>
          <TransactionIcon style={{ width: "50px", height: "50px", marginRight: "10px",background:'transparent' }} />
         Kiosk Sales Report
      </div>
      <div className="flexRow">
        <div className="flexRow filterDiv">
          <SearchBar
            placeholder="Search Kiosks Sales Report"
            onChange={(e)=>{
              const value = e.target.value.toLowerCase();
              const filtered = data.filter(item => 
                Object.keys(item).some(key => 
                  item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase()) 
                ) 
              );
              setFilteredData(filtered);
            }}
          />
        </div>
        &nbsp;&nbsp;
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
        </div>
        <div className="spacer"></div>
        <Button variant={"success"} size={'xs'} onClick={()=>exportToCSV(filteredData)}> Export to CSV </Button>
      </div>
        <br/>
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          highlightOnHover
          style={{zIndex:0}}
        />
      
    </div>
  );
};

export default KioskReports;
