import React from "react";
import { ReactComponent as DashboardIcon } from "../icons/dashboard.svg";
import MotherWalletCard from "../components/dashboard/MotherWalletCard";
import BucorWallet from "../components/dashboard/BuCorWallet";
import ForReprocessCard from "../components/dashboard/ForReprocessCard";
import ItemRequestsCard from "../components/dashboard/ItemRequestsCard";
import ServiceFees from "../components/dashboard/ServiceFees";
import PosFees from "../components/dashboard/PosFees";
import MerchantPadalaRates from "../components/dashboard/MerchantPadalaRates";
import IPXWalletCard from "../components/dashboard/IPXWalletCard";

const HomePage = () => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
        <DashboardIcon style={{ width: "50px", height: "50px", marginRight: "10px" }} />
        <h2>Admin Booth Dashboard</h2>
      </div>
      <p>Welcome to the dashboard!</p>
      <div className="flexRow" style={{paddingTop: "50px", justifyContent: "center"}}>
        <IPXWalletCard />
      </div>
      <div className="flexRow" style={{paddingTop: "10px", justifyContent: "center"}}>
        <MotherWalletCard />
        {/* <BucorWallet /> */}
        <ServiceFees />
      </div>

      <div className="flexRow" style={{paddingTop: "10px", justifyContent: "center"}}>
        <PosFees />
        {/* <BucorWallet /> */}
        <MerchantPadalaRates />
      </div>

      <div className="flexRow" style={{padding: "10px", justifyContent: "center"}}>
        <ForReprocessCard />
        <ItemRequestsCard />
      </div>
    </div>
  );
};

export default HomePage;
